<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 v-if="!edit_form" class="card-label">Event Add</h3>
          <h3 v-else class="card-label">Event Update</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToEventsList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_event_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid">
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="event.name"
                    :rules="[v => !!v || 'Name is required']"
                    label="Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Date"
                    v-model="event.date"
                    :rules="dateRule"
                    type="date"
                    outlined
                    dense
                  ></v-text-field>

                  <!-- <v-datetime-picker
                    class="pr-2"
                    v-model="event.date"
                    :rules="[v => !!v || 'Date & Time is required']"
                    label="Date & Time"
                    outlined
                    dense
                  ></v-datetime-picker> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="event.location"
                    :rules="[v => !!v || 'Location is required']"
                    label="Location"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      label="Time"
                      :rules="timeRule"
                      class="pr-2"
                      v-model="event.time"
                      type="time"
                      value="00:00"
                      outlined
                      dense
                    ></v-text-field>
                </v-col>  
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="event.price"
                    :rules="numberRule"
                    label="Price"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters  v-if="event.available_for_volunteer">
                <v-col cols="12" lg="12">
                  <v-textarea
                    v-model="event.volunteer_roles"
                    label="Volunteer Roles"
                    dense
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" lg="12">
                  Time Slots:
                  <v-btn
                    class="ml-5"
                    color="primary"
                    outlined
                    @click="addNewTimeSlot()"
                  >
                    + Time Slot
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-center font-weight-bolder" cols="12" sm="3">
                  From
                </v-col>
                <v-col class="text-center font-weight-bolder" cols="12" sm="3">
                  To
                </v-col>
                <v-col class="font-weight-bolder" cols="12" sm="4">
                  Available for:
                </v-col>
                 <v-col class="text-center font-weight-bolder" cols="12" sm="1">
                  Action
                </v-col>
              </v-row>

              <v-row
                no-gutters
                v-bind:key="index"
                v-for="(time_slot, index) in event.time_slots"
              >
                <v-col cols="12" sm="3">
                  <v-text-field
                    class="pr-2"
                    v-model="time_slot.from_time"
                    type="time"
                    value="00:00"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    class="pr-2"
                    v-model="time_slot.to_time"
                    type="time"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="mt-2">
                  <v-checkbox
                    style="margin:0px; padding:0px;"
                    label="Attend"
                    v-model="time_slot.available_for_attend"
                    required
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="2" class="mt-2">
                  <v-checkbox
                    style="margin:0px; padding:0px;"
                    label="Volunteer"
                    v-model="time_slot.available_for_volunteer"
                    required
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-btn
                    color="red"
                    class="mt-1"
                    outlined
                    title="Remove Time Slot"
                    @click="removeTimeSlot(index)"
                  >
                    X
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="mt-2" v-if="time_slot.available_for_volunteer">
                  <v-text-field
                    class="pr-2"
                    label="No Of Volunteers Needed"
                    v-model="time_slot.no_of_volunteers_needed"
                    :rules="volunteersNeededRules"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mt-2" v-if="time_slot.available_for_volunteer">
                  <v-textarea
                    label="Volunteer Roles"
                    v-model="time_slot.volunteer_roles"
                    :rules="volunteerRules"
                    dense
                    outlined
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row fluid class="card-footer pl-3 pt-3">
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Events-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import store from '@/core/services/store'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { format, parseISO } from "date-fns";
import { STORE_EVENT, UPDATE_EVENT } from "@/core/services/store/event.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "EventForm",
  vuetify: new Vuetify(),
  data() {
    return {
      permissions: [],
      date: format(
          parseISO(new Date().toISOString()),
          "yyyy-MM-dd"
        ),
      event: {
        name: "",
        date: format(
          parseISO(new Date().toISOString()),
          "yyyy-MM-dd"
        ),
        time: "",
        location: "",
        price: "0",
        available_for_volunteer: 0,
        available_for_attend: 0,
        no_of_volunteers_needed: "",
        volunteer_roles: "",
        time_slots: [
          {
            from_time: "",
            to_time: "",
            available_for_attend: false,
            available_for_volunteer: false,
            no_of_volunteers_needed: 0,
            volunteer_role:""
          }
        ]
      },
      time: null,
      menu2: false,
      menu1: false,
      modal2: false,
      valid: true,
      disable_btn: false,
      priceRules: [v => !!v || "Price is required"],
      volunteerRules: [v => !!v || "Volunteer Roles is required"],
      volunteersNeededRules: [v => !!v || "No Of Volunteers Needed is required"],
      numberRule: [
        v => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999999) return true;
          return "Please add proper number";
        }
      ],
      dateRule: [
        v => {
          if (!v.trim()) return true;
          var d1 = new Date();
          var d2 = new Date(v);
          if (d1 <= d2) return true;
          return "Please add future date";
        }
      ],
      timeRule: [
        v => !!v || "Please add time"
      ],
      phoneRules: [
        v => !!v || "Valid phone number is required",
        v =>
          (v && v.length <= 15) || "Phone number must be less than 16 numbers",
        v =>
          (v && v.length >= 8) || "Phone number must be greater than 7 numbers"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      edit_form: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany","companyUsers", "getPermissions"])
  },
  watch: {
    getPermissions: function(newVal) {
      this.permissions = newVal;
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "events/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.events) {
            context.event = result.data.events;
          } else {
            // context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "Event load error!", "error");
          }
        },
        function(error) {
          context.disable_btn = false;
          Swal.fire("Error", "Event not found!", "error");
          context.$router.push({ name: "eventsList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Event" }]);

    const event_form = KTUtil.getById("kt_event_form");

    this.fv = formValidation(event_form, {});

    EventBus.$on("UPDATE_EVENT_ERROR", function(payLoad) {
      context.$router.push({ name: "eventsList" });
    });

    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    removeTimeSlot(time_slot_index) {
      this.event.time_slots.pop(time_slot_index);
    },
    addNewTimeSlot() {
      this.event.time_slots.push({
        from_time: "",
        to_time: "",
        available_for_volunteer: false,
        available_for_attend: false,
        no_of_volunteers_needed: 0,
        volunteer_role:""
      });
    },
    updateEvent() {
      let context = this;
      if (!this.event.available_for_volunteer) {
        this.event.available_for_volunteer = 0;
      }
      if (!this.event.available_for_attend) {
        this.event.available_for_attend = 0;
      }
      this.$store.dispatch(UPDATE_EVENT, this.event).then(() => {
        context.$router.push({ name: "eventsList" });
      });
    },
    redirectToEventsList() {
      let context = this;
      context.$router.go(-1);
    },
    submitEvent() {
      let context = this;
      this.$store.dispatch(STORE_EVENT, this.event).then(() => {
        context.disable_btn = false;
        context.$router.push({ name: "eventsList" });
      });
    },
    validateEditForm() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateEvent();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.submitEvent();
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
